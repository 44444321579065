import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/ApplyView.vue'),
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/StatusView.vue'),
  },
]

const router = createRouter({
  scrollBehavior(to) {
    // Always scroll to top unless otherwise.
    if (to.hash) {
      return {el: to.hash}
    } else {
      return { top: 0 }
    }
  },

  history: createWebHistory(),
  routes
});

export default router